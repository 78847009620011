import uiRouter from '@uirouter/angularjs';
import template from './breakFrame.html';
import fancyTemplate from './breakFrame.fancy.html';

export default angular.module('eventix.breakFrame',[uiRouter])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.breakFrame', {
            url: '/break/:shopId',
            resolve: /*@ngInject*/{},
            views: {
                main: {
                    controller: 'BreakFrameController',
                    controllerAs: 'vm',
                    templateUrl: template
                }
            }
        });
    })
    .controller('BreakFrameController', function(frameInfo, $transition$) {
        const vm = this;
        vm.isFramed = frameInfo;
        vm.shopId = $transition$.params().shopId;
    }).name;

