import styling from './summary.less';
import template from './summary.html';

export default angular.module('eventix.shop.summary',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.summary', {
            url: '/summary',
            views: {
                shop: {
                    controller: 'SummaryController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .controller('SummaryController', function($state, events, order, shop, tickets, products, metaData, Currencies, Locales,
        UIMessages, $timeout, $window, paymentMethods, Company, Whitelabel, $analytics, beforeUnload, ShopAnalytics, globalProducts) {
        var vm = this;
        vm.shop = shop;
        vm.order = order;
        vm.events = events;
        vm.locales = Locales;
        vm.tickets = tickets;
        vm.metaData = metaData;
        vm.products = order.products;
        vm.currencies = Currencies;
        vm.paymentMethods = paymentMethods;
        vm.order.paymentProvider = _.first(vm.paymentMethods).guid;
        vm.order.updateOrderTotal();
        vm.company = Company.cached.first();
        vm.whitelabel = Whitelabel.cached.first();
        vm.isRedirecting = false;
        vm.globalProducts = globalProducts;

        if(_.flatten(_.values(order.reservations)).length < 1)
            $state.go('eventix.shop.events', {shopId: shop.guid});
        vm.next = () => {
            if(vm.busy) return vm;
            // check if terms are accepted
            if((vm.shop.company_terms || vm.shop.global_terms) && !vm.acceptTerms)
                return UIMessages.push('common.notice.acceptTerms');
            // check if bank is required and selected
            let paymentProvider = _.find(vm.paymentMethods, { guid: vm.order.paymentProvider });
            if(vm.order.orderTotal > 0 &&
                !_.isEmpty(paymentProvider.issuers) &&
                (_.isNil(vm.order.paymentProviderIssuer) || vm.order.paymentProviderIssuer === '')) {
                vm.bankError = true;
                return UIMessages.push('common.notice.selectPaymentIssuer');
            }
            vm.busy = true;
            return order.submit()
                .then(response => {
                    vm.isRedirecting = true;
                    beforeUnload.clear();
                    $timeout(() => {
                        vm.order.reset();
                        $window.top.location = response.data.redirectUrl;
                    }, 1000);
                    $timeout(() => {
                        $state.go('eventix.shop.tickets', { shopId: shop.guid });
                    }, 1500);
                }, error => {
                    vm.busy = false;
                    vm.isRedirecting = false;
                    if(error.status === 406)
                        $state.go('eventix.shop.personalDetails', { shopId: shop.guid });
                });
        };
        vm.back = () => $state.go('eventix.shop.personalDetails', { shopId: shop.guid });
        vm.receiver = [vm.order.receiver.firstname, vm.order.receiver.lastname, '(' + vm.order.receiver.email + ')'].join(' ');
        ShopAnalytics.checkoutStepTwo();

    }).name;
