import template from './personalDetails.html';
require('./personalDetails.less')

export default angular.module('eventix.shop.personalDetails', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.personalDetails', {
            url: '/personalDetails',
            views: {
                shop: {
                    controller: 'PersonalDetailsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('PersonalDetailsController', function($state, $scope, $filter, $q, ShopAnalytics, UIMessages, FB, Currencies, shop, order, tickets, products, globalProducts) {
        var vm = this;
        if(_.flatten(_.values(order.reservations)).length < 1)
            $state.go('eventix.shop.events', {shopId: shop.guid});
        if(order.receiver.email || FB.disabled) // Don't show facebook login button if profile is already filled
            vm.manualEntry = true;
        vm.order = order;
        vm.shop = shop;
        vm.currencies = Currencies;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');

        vm.products = products;
        vm.globalProducts = globalProducts
        vm.verifyEmail = function(error, valid, suggestion) {
            if(!suggestion)
                return;
            if(!_.get(vm.order.receiver,'$errors.email'))
                _.set(vm.order.receiver, '$errors.email', []);
            vm.order.receiver.$errors.email.push($filter('translate')('common.notice.didYouMean', {suggestion: suggestion }));
        };

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.tickets', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.tickets', { shopId: shop.guid, eventId });
        };
        vm.next = () => {
            if (!_.isEmpty(order.validateReceiver())) {
                console.error('next > invalidReceiver', order.receiver.$errors);

                vm.manualEntry = true;

                return;
            }

            if (order.hasInvalidReservations()) {
                let invalidReservation = _.find(_.flatten(_.values(vm.order.reservations)), r => r.$invalid());
                let collapseToOpen;

                if (invalidReservation) {
                    // Find the collapse with the invalid reservation
                    collapseToOpen = _.find($scope.accordion.groups, group => {
                        return _.get(group, '$parent.ticketReservation.reservation') === invalidReservation.reservation;
                    });

                    console.error('next > invalidReservation', collapseToOpen, invalidReservation);
                } else {
                    // Find the collapse without a ticketReservation
                    collapseToOpen = _.find($scope.accordion.groups, group => !group.$parent.ticketReservation);

                    console.error('next > missingTicketReservation', collapseToOpen);
                }

                if (collapseToOpen && !collapseToOpen.isOpen) {
                    collapseToOpen.toggleOpen();
                }

                return;
            }

            let unopened = _.findKey(vm.hasOpened, v => !v);

            if(unopened) {
                let toOpen = _.find($scope.accordion.groups, g => _.get(g.$parent,'ticketReservation.reservation') === unopened);

                if(toOpen) {
                    console.error('next > unopened');

                    vm.hasOpened[unopened] = true;

                    toOpen.toggleOpen();

                    return;
                }
            }

            $state.go('eventix.shop.summary',{ shopId: shop.guid });
        };

        ShopAnalytics.checkoutStepOne();
        /*
        * We keep tabs on whether people understand the accordion.
        * If they have unopened tabs, goto that tab when you press "Next"
        */
        vm.hasOpened = { };
        _.forEach(vm.order.reservations, (ticketReservations, ticketGuid) => {
            if (!vm.order.showTicketReservationForm(ticketGuid))
                return;
            _.forEach(ticketReservations, reservation => {
                vm.hasOpened[reservation.reservation] = false;
            });
        });
        vm.logOpeningCollapse = function(reservationGuid) {
            vm.hasOpened[reservationGuid] = true;
        };

        vm.facebookLogin = function() {
            order.loginWithFacebook().catch(() => {
                UIMessages.push('common.shop.facebookError');
                return $q.resolve();
            }).then(() => {
                vm.manualEntry = true;
            });
        };
    }).name;
