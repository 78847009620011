import template from './error.html';

export default angular.module('eventix.shop.error',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shoperror', {
            url: '/error?shop',
            views: {
                main: {
                    controller: 'ErrorController as vm',
                    templateUrl: template
                },
                header: {
                    template: function() {
                        const isFancy = (window.location.toString()).indexOf(FANCY_SHOP_ROOT) === 0;
                        if(!isFancy) return '';
                        return `<nav class="header-nav navbar top-nav">&nbsp;</nav>
                        <ol class="checkout breadcrumb">
                            <li class="active">&nbsp;</li>
                            <li>&nbsp;</li>
                            <li>&nbsp;</li>
                        </ol>`;
                    }
                }
            },
            resolve: /*@ngInject*/{
                isReload: function($state) {
                    return !$state.includes('eventix.shop');
                }
            }
        });
    })
    .controller('ErrorController', function($state, stateChangeErrorService, isReload) {
        var vm = this;
        vm.isAdmin = NODE_ENV === 'development';
        vm.error = stateChangeErrorService.get();
        switch(_.get(vm.error, 'error.type')) {
            case 404:
                vm.title = 'models.shop.notice.notFound';
                break;
            default:
                vm.title = 'common.notice.unknownError';
                break;
        }
        vm.errorService = stateChangeErrorService;
        if(isReload && !vm.errorService.retries)
            vm.errorService.retry();
    }).name;

