import flatbuffers from "./flatbuffers";
import services from "./ccfe_generated";
function Socketer(storeId, s) {
    const settings = Object.assign({
            success: [],
            error: [],
            close: [],
            place: [],
            token: window.location.search.substr(1) || null,
            storeId: storeId,
            host: WS_WAAS_HOST,
        },
        s,
        {
            spot: -1,
        });

    // Keep track of pings
    let ping = -1;
    const pingInterval = 3000;

    let ws = null;

    // When true; we expect a close
    let aboutToClose = false;
    let wasConnected = false;

    // connection variables, for normal and backoff connection
    let boPos = 0;
    let bo = [0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55];

    // Connection timer
    let cw = -1;

    function buildHost() {
        // return `${settings.host}/${settings.storeId || ''}/${settings.token || ''}`;
        return `${settings.host}/${settings.storeId || ''}`;
    }

    function clearBackOff() {
        if (cw === -1) {
            return;
        }

        window.clearTimeout(cw)
        cw = -1;
        // boPos = 0;
    }

    function connect(force) {
        if (ws !== null) {
            if (force) {
                ws.addEventListener('close', () => connect());
                close();
                return;
            } else {
                // We are not forcing, so return
                return
            }
        }

        // Always clear timer
        clearBackOff()

        const url = buildHost()

        // Create new socket connection
        ws = new WebSocket(url);
        ws.binaryType = 'arraybuffer';
        ws.onmessage = handleMessage;
        ws.onclose = function (e) {
            // Stop pinging
            if (ping > -1) {
                window.clearInterval(ping)
                ping = -1;
            }

            callCallbacks(settings.close)

            ws = null;
            if (!aboutToClose && wasConnected) {
                aboutToClose = false;
                wasConnected = false;
                window.setTimeout(connect, 0);
            }
        };

        ws.onerror = function () {
            // Stop pinging
            if (ping > -1) {
                window.clearInterval(ping)
                ping = -1;
            }

            // Attempt to reconnect, after backoff
            var lbo = bo[Math.min(boPos++, bo.length - 1)];
            ws = null

            callCallbacks(settings.error)
        };

        // Reset timeout waiter id
        ws.onopen = function () {
            clearBackOff()

            // Start ping
            ping = window.setInterval(sendPing, pingInterval)
            sendPing();
        }
    }

    /**
     * @param {flatbuffers.Builder} b
     * @param {services.ccfe.Any} packetType
     * @param {flatbuffers.Offset} packetOffset
     * @returns {boolean}
     */
    function sendPacket(b, packetType, packetOffset) {
        if (socketReady()) {
            b.finish(services.ccfe.Packet.createPacket(b, packetType, packetOffset))
            ws.send(b.asUint8Array())
        }

        return false;
    }

    function socketReady() {
        return ws != null && ws.readyState === WebSocket.OPEN;
    }

    function sendPing() {
        let b = new flatbuffers.Builder(128);
        let ping = services.ccfe.Empty.createEmpty(b, services.ccfe.EmptyType.Ping);
        sendPacket(b, services.ccfe.Any.Empty, ping)
    }

    function close() {
        if (ws == null) {
            return false;
        }

        ws.close();
        return true;
    }

    // Private methods
    function handleMessage(evt) {
        if (!ws) {
            return;
        }

        // Automatically decode as packet
        handlePacket(services.ccfe.Packet.getRootAsPacket(new flatbuffers.ByteBuffer(new Uint8Array(evt.data))))
    }

    function callCallbacks(arr, ...data) {
        window.setTimeout(function (arr) {
            arr.forEach(a => window.setTimeout(a, 0, ...data))
        }, 0, arr)
    }

    function handlePacket(packet) {
        switch (packet.packetType()) {
            case services.ccfe.Any.Customer:
                let i = new services.ccfe.Customer();
                packet.packet(i);

                let n = 0
                let arr = i.numArray()
                for(let i in arr) {
                    n = (n << 8) + arr[i];
                }

                // let buffer = new ArrayBuffer(i.numArray())
                // console.log( new BigUint64Array(i.numArray()) );

                settings.token = i.token();
                settings.spot = n;
                // settings.spot = (new DataView(i.numArray(), 0)).getBigUint64(0, false)
                wasConnected = true;

                callCallbacks(settings.success)

                break;
            case services.ccfe.Any.Empty:
        }
    }

    connect();

    return {
        host: function () {
            return buildHost();
        },

        connect: function () {
            connect()
        },

        reconnect: function () {
            connect(true);
        },

        ready: function () {
            return socketReady();
        },

        ping: function () {
            sendPing()
        },

        reset: function () {
            settings.token = null;
            this.reconnect();
        },

        token: function () {
            return settings.token;
        },

        spot: function () {
            return settings.spot;
        },

        status: function () {
            return settings.status;
        },

        log: function(level, message, opts) {

            let b = new flatbuffers.Builder(128);
            let m = b.createString(message);

            let fieldsArr = [];
            for (const [key, value] of Object.entries(opts)) {
                fieldsArr.push(services.la.Field.createField(b, b.createString(key), b.createString(value)))
            }

            let fields = services.la.Error.createFieldsVector(b, fieldsArr)

            let err = services.la.Error.createError(b, m, level, fields);
            sendPacket(b, services.ccfe.Any.services_la_Error, err)
        }
    }
}
export default Socketer;
