// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @const
 * @namespace
 */
var services = services || {};

/**
 * @const
 * @namespace
 */
services.any = services.any || {};

/**
 * @const
 * @namespace
 */
services.ccfe = services.ccfe || {};

/**
 * @const
 * @namespace
 */
services.la = services.la || {};

/**
 * @enum {number}
 */
services.ccfe.ChangeType = {
  Unknown: 0,
  Arrived: 1,
  Shopping: 2,
  Done: 3,
  Paying: 4,
  Left: 5,
  ForceLeft: 6
};

/**
 * @enum {string}
 */
services.ccfe.ChangeTypeName = {
  '0': 'Unknown',
  '1': 'Arrived',
  '2': 'Shopping',
  '3': 'Done',
  '4': 'Paying',
  '5': 'Left',
  '6': 'ForceLeft'
};

/**
 * @enum {number}
 */
services.ccfe.EmptyType = {
  Ping: 0,
  Bye: 1,
  NoRetry: 2,
  KnownCustomer: 3,
  UnknownCustomer: 4,
  Error: 5,
  Ok: 6
};

/**
 * @enum {string}
 */
services.ccfe.EmptyTypeName = {
  '0': 'Ping',
  '1': 'Bye',
  '2': 'NoRetry',
  '3': 'KnownCustomer',
  '4': 'UnknownCustomer',
  '5': 'Error',
  '6': 'Ok'
};

/**
 * @enum {number}
 */
services.ccfe.Any = {
  NONE: 0,
  Customer: 1,
  Empty: 2,
  services_la_Error: 3
};

/**
 * @enum {string}
 */
services.ccfe.AnyName = {
  '0': 'NONE',
  '1': 'Customer',
  '2': 'Empty',
  '3': 'services_la_Error'
};

/**
 * @constructor
 */
services.ccfe.Customer = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {services.ccfe.Customer}
 */
services.ccfe.Customer.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Customer=} obj
 * @returns {services.ccfe.Customer}
 */
services.ccfe.Customer.getRootAsCustomer = function(bb, obj) {
  return (obj || new services.ccfe.Customer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Customer=} obj
 * @returns {services.ccfe.Customer}
 */
services.ccfe.Customer.getSizePrefixedRootAsCustomer = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new services.ccfe.Customer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
services.ccfe.Customer.prototype.token = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @returns {number}
 */
services.ccfe.Customer.prototype.num = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readUint8(this.bb.__vector(this.bb_pos + offset) + index) : 0;
};

/**
 * @returns {number}
 */
services.ccfe.Customer.prototype.numLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {Uint8Array}
 */
services.ccfe.Customer.prototype.numArray = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? new Uint8Array(this.bb.bytes().buffer, this.bb.bytes().byteOffset + this.bb.__vector(this.bb_pos + offset), this.bb.__vector_len(this.bb_pos + offset)) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
services.ccfe.Customer.startCustomer = function(builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} tokenOffset
 */
services.ccfe.Customer.addToken = function(builder, tokenOffset) {
  builder.addFieldOffset(0, tokenOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} numOffset
 */
services.ccfe.Customer.addNum = function(builder, numOffset) {
  builder.addFieldOffset(1, numOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<number>} data
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Customer.createNumVector = function(builder, data) {
  builder.startVector(1, data.length, 1);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
services.ccfe.Customer.startNumVector = function(builder, numElems) {
  builder.startVector(1, numElems, 1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Customer.endCustomer = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} tokenOffset
 * @param {flatbuffers.Offset} numOffset
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Customer.createCustomer = function(builder, tokenOffset, numOffset) {
  services.ccfe.Customer.startCustomer(builder);
  services.ccfe.Customer.addToken(builder, tokenOffset);
  services.ccfe.Customer.addNum(builder, numOffset);
  return services.ccfe.Customer.endCustomer(builder);
}

/**
 * @constructor
 */
services.ccfe.Empty = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {services.ccfe.Empty}
 */
services.ccfe.Empty.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Empty=} obj
 * @returns {services.ccfe.Empty}
 */
services.ccfe.Empty.getRootAsEmpty = function(bb, obj) {
  return (obj || new services.ccfe.Empty).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Empty=} obj
 * @returns {services.ccfe.Empty}
 */
services.ccfe.Empty.getSizePrefixedRootAsEmpty = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new services.ccfe.Empty).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {services.ccfe.EmptyType}
 */
services.ccfe.Empty.prototype.type = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {services.ccfe.EmptyType} */ (this.bb.readInt8(this.bb_pos + offset)) : services.ccfe.EmptyType.Ping;
};

/**
 * @param {services.ccfe.EmptyType} value
 * @returns {boolean}
 */
services.ccfe.Empty.prototype.mutate_type = function(value) {
  var offset = this.bb.__offset(this.bb_pos, 4);

  if (offset === 0) {
    return false;
  }

  this.bb.writeInt8(this.bb_pos + offset, value);
  return true;
};

/**
 * @param {flatbuffers.Builder} builder
 */
services.ccfe.Empty.startEmpty = function(builder) {
  builder.startObject(1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {services.ccfe.EmptyType} type
 */
services.ccfe.Empty.addType = function(builder, type) {
  builder.addFieldInt8(0, type, services.ccfe.EmptyType.Ping);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Empty.endEmpty = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {services.ccfe.EmptyType} type
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Empty.createEmpty = function(builder, type) {
  services.ccfe.Empty.startEmpty(builder);
  services.ccfe.Empty.addType(builder, type);
  return services.ccfe.Empty.endEmpty(builder);
}

/**
 * @constructor
 */
services.ccfe.Packet = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {services.ccfe.Packet}
 */
services.ccfe.Packet.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Packet=} obj
 * @returns {services.ccfe.Packet}
 */
services.ccfe.Packet.getRootAsPacket = function(bb, obj) {
  return (obj || new services.ccfe.Packet).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {services.ccfe.Packet=} obj
 * @returns {services.ccfe.Packet}
 */
services.ccfe.Packet.getSizePrefixedRootAsPacket = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new services.ccfe.Packet).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {services.ccfe.Any}
 */
services.ccfe.Packet.prototype.packetType = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {services.ccfe.Any} */ (this.bb.readUint8(this.bb_pos + offset)) : services.ccfe.Any.NONE;
};

/**
 * @param {flatbuffers.Table} obj
 * @returns {?flatbuffers.Table}
 */
services.ccfe.Packet.prototype.packet = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__union(obj, this.bb_pos + offset) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
services.ccfe.Packet.startPacket = function(builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {services.ccfe.Any} packetType
 */
services.ccfe.Packet.addPacketType = function(builder, packetType) {
  builder.addFieldInt8(0, packetType, services.ccfe.Any.NONE);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} packetOffset
 */
services.ccfe.Packet.addPacket = function(builder, packetOffset) {
  builder.addFieldOffset(1, packetOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Packet.endPacket = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {services.ccfe.Any} packetType
 * @param {flatbuffers.Offset} packetOffset
 * @returns {flatbuffers.Offset}
 */
services.ccfe.Packet.createPacket = function(builder, packetType, packetOffset) {
  services.ccfe.Packet.startPacket(builder);
  services.ccfe.Packet.addPacketType(builder, packetType);
  services.ccfe.Packet.addPacket(builder, packetOffset);
  return services.ccfe.Packet.endPacket(builder);
}

// Exports for Node.js and RequireJS
// this.services = services;
export default services;
